import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTheme } from '@mui/material';
import { ReactComponent as AvatarIcon } from '@icons/wolfkit-solid/user-solid.svg';
import Icon from '@shared/ui/icons/Icon';
const AvatarStyled = styled.div(props => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'inherit',
    minWidth: `${props.size}px`,
    minHeight: `${props.size}px`,
    width: `${props.size}px`,
    height: `${props.size}px`,
    borderRadius: '50%',
    border: props.withEdging ? `1px solid ${props.theme.customColors.portfolio.hover}` : 'unset',
    fontSize: props.variant === 'text' ? `${props.theme.spacing_sizes.m}px` : `${props.theme.spacing_sizes.xs * 2}px`,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: props.variant === 'text' ? `${props.theme.spacing_sizes.s * 3}px` : `${props.theme.spacing_sizes.l}px`,
    letterSpacing: props.variant === 'text' ? 'normal' : '-0.176px',
}));
const Avatar = ({ className = undefined, size = 34, style = undefined, imgUrl = undefined, variant = 'img', color = 'primary', text = undefined, withEdging = false, onClick = () => { }, }) => {
    const theme = useTheme();
    const displayText = useMemo(() => !!text &&
        (variant === 'text' || variant === 'initials'), [variant, text]);
    const avatarColor = useMemo(() => {
        if (color === 'secondary') {
            return theme.customColors.button.onPrimary;
        }
        // TODO: check 'badge.profileTabActive.surface/onSurface' color,
        // its now used in two different by meaning places. Should move and name it differently
        return theme.customColors.badge.profileTabActive.onSurface;
    }, [color, theme]);
    const avatarBackground = useMemo(() => {
        let backgroundColor = theme.customColors.badge.profileTabActive.surface;
        if (color === 'secondary') {
            backgroundColor = theme.customColors.input.borderHover;
        }
        return `url(${imgUrl}) ${backgroundColor} center / cover no-repeat`;
    }, [color, imgUrl, theme]);
    return (_jsxs(AvatarStyled, { className: className, size: size, style: Object.assign({ color: avatarColor, background: avatarBackground }, style), imgUrl: imgUrl, variant: variant, withEdging: withEdging, onClick: onClick, children: [variant === 'avatar-icon' && (_jsx(Icon, { size: 16, IconComponent: AvatarIcon })), displayText && (text)] }));
};
export default Avatar;
